import React, { useRef } from "react"
import "../../css/unity.css"

export var ProgressBar = ({ percent }) => {
	const [width, setWidth] = React.useState(0)
	const [value, setValue] = React.useState(0)
	const ref = useRef(null)

	// Dynamically calculate progress bar so will properly fit on
	// all screens AND be accurate
	// React.useEffect(() => {
	// 	setWidth(ref.current.clientWidth)
	// })

	// React.useEffect(() => {
	// 	setValue(percent * width)
	// })

	// progress_shell = background/base of progress bar
	// progress_fill = color/fill for the current progress of bar
	return (
	<div>
			<br />
			{/* <div className="progress_fill" style={{ width: `${value}px` }} /> */}
			<div class="spinner-box" ref={ref} >
				<div class="blue-orbit leo">
				</div>

				<div class="green-orbit leo">
				</div>
				
				<div class="red-orbit leo">
				</div>
				
				<div class="white-orbit w1 leo">
				</div><div class="white-orbit w2 leo">
				</div><div class="white-orbit w3 leo">
				</div>
				</div>
				<br />
				<br />
	</div>


	)
}

export default ProgressBar
