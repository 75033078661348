import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types"
import Unity, { UnityContext } from "react-unity-webgl"
// import API from "@aws-amplify/api"
// import Auth from "@aws-amplify/auth"
import { Box, Button, Container, Typography } from "@mui/material"
import "../../css/unity.css"
import Unity_Loading from "./Unity_Loading"
import Unity_Controls from "./Unity_Controls"
// react-unity-webgl API Reference: https://github.com/jeffreylanters/react-unity-webgl

function Unity_Embed(props) {
  const initialState = {
    user: "",
    dialog: false,
    loading: true,
    percent: 0,
    width: 960,
    height: 600,
    flagCount: 0,
    totalFlagCount: 0,
  }
  const [state, setState] = useState(initialState)
    // IMPORTANT: This is how it knows which build files to use for the scene
    const unityContext = new UnityContext({
      loaderUrl: props.loaderUrl,
      dataUrl: props.dataUrl,
      frameworkUrl: props.frameworkUrl,
      codeUrl: props.codeUrl
    })
    // For knowing when all loading is complete (can view scene now)
    unityContext.on("loaded", () => {
      setState(prevState => ({ ...prevState, loading: false }))
    })
    // For progress bar updates
    const unity = React.createRef()
    unityContext.on("progress", percent => {
      setState(prevState => ({ ...prevState, percent: percent.toFixed(2) }))
    })
    // IMPORTANT: If not specified, user will get a pop-up error and reload issues
    //  when the page need to refresh or has an exit issue
    // unityContext.on("quitted", () => { })
    // const getUser = async () => {
    //   return await Auth.currentAuthenticatedUser()
    // }
  // Functions to run upon start
  // Calculate size of Unity scene dynamically to accomidate screen size
  useEffect(() => {
    let h = unity.current.offsetHeight
    if (h) {
      setState(prevState => ({ ...prevState,height: h }))
      // Unity's suggested ratio is 1.6 (i.e., default is usually w=960 and h=600)
      setState(prevState => ({ ...prevState, width: parseInt(h * 1.6) }))
    }
    // if (props.public === false) {
    //   getUser().then( user=> {
    //     console.log(user)
    //   setState(prevState => (
    //         { ...prevState, user: user }
    //         ), getFlagCount()
    //     )
    // });
    // }
  }, []);
  // const getFlagCount = async () => {
  //   let user = await Auth.currentAuthenticatedUser()
  //   var token = user?.signInUserSession?.idToken?.jwtToken
  //   var url = "flag/count?location=" + props.awsName;

  //   // var token = state.user.signInUserSession.idToken.jwtToken;
  //   // var url = "/flag?location=" + props.awsName;
  //   try {
  //     var data = await API.get("code", url, {
  //       headers: {
  //         Authorization: token
  //       }
  //     });
  //     setState(prevState => ({ ...prevState, totalFlagCount: data.total }));
  //     setState(prevState => ({ ...prevState, flagCount: data.completed }));
  //   }
  //   catch (err) {
  //     console.log(err.response.data.error)
  //     setState(prevState => ({ ...prevState, input: err.response.data["error"] }))
  //   }
  // }
  const fullScreen = () => {
    unityContext.setFullscreen(true)
  }

  // @Luis Plascencia This function would need to be run after scene is completely loaded and be updated with Object/Function
  // Send completion code for scene to Unity (for displaying if the user completes the scene)
  const sendCodetoUnity = () => {
    // send(Unity object name, Unity function name, data to send)
    unityContext.send("Object", "Function", props.sceneCompletionCode)
    console.log("Sent Code")
  }
  const sendStudentIDtoUnity = () => {
    // for Christian's ReceiveStudentID function
    unityContext.send("[Bridge]", "ReceiveStudentID", "[insert Student ID]")
    console.log('Sent StudentID ')
  }
  // Send code to Unity when scene is completely loaded
  // This should also send the studentID to Unity as of 9.6.22
  if (!state.loading) {
    sendCodetoUnity()
    sendStudentIDtoUnity()
  }
  return (
    // <Container className='unity-container' maxWidth="sm" style={{backgroundColor:'black', display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
      <Box>
        {state.dialog ?
          <Unity_Controls />
          :
          null
        }
        {/* Loading page for Unity scene*/}
        {state.loading && <Unity_Loading percent={state.percent} scene_name={props.scene_name} />}
        {/* Scene display for Unity (loading complete)*/}
        <Box ref={unity} className="scene_page" height='90%'  width='90%' style={{ 
          visibility: state.loading && "hidden", 
          backgroundColor:'#030F21', 
          display: 'flex',  
          justifyContent:'center',
          alignItems:'center', 
          minHeight: '100vh',
          width: "100vw"
          }}> 
            <Unity
              className="unity_scene"
              unityContext={unityContext}
              // style={{ width: state.width, height: state.height }}
              style={{ width: '90%', height: '90%' }}
            />
          <Box className="unity_bar row" pt={2}>
            {/* <Button class="light-button" onClick={() => 
              unityContext.send("[Bridge]", "ReceiveStudentID", "[insert Student ID]")}>
              Christian's Button
            </Button> */}
            <Button id="button_room" onClick={() => setState({ dialog: !state.dialog })}>
              Controls
            </Button>
            {props.public === false ?
              <Typography variant="body" className="content" style={{ fontWeight: "normal", color:"white"}}>
                <Typography variant="body" className="content" style={{ fontWeight: "bold", color:"white"}}>
                  Flags Found in {props.scene_name}: </Typography >
                {state.flagCount}/{state.totalFlagCount}
              </Typography>
              :
              null
            }
            <Typography className="section-subtitle-dark" variant="h" style={{color:'white'}}>
              {props.scene_name}
            </Typography>
          </Box>
        </Box>
      </Box>
    // </Container>
  )
}
export default Unity_Embed
Unity_Embed.propTypes = {
  scene_name: PropTypes.string.isRequired,
  loaderUrl: PropTypes.string.isRequired,
  dataUrl: PropTypes.string.isRequired,
  frameworkUrl: PropTypes.string.isRequired,
  codeUrl: PropTypes.string.isRequired,
  sceneCompletionCode: PropTypes.string.isRequired
}