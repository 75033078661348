import "../../css/unity.css"
import ProgressBar from "./ProgressBar"
import cci_logo from "../../images/cci_logo.png"
import logo from "../../images/logo.png"
import satellite_logo from "../../images/favicon-16x16.png"
import satellite_large from "../../images/satelite_large.png"
import Box from "@material-ui/core/Box"

export var Unity_Loading = ({ percent, scene_name }) => {
	return (
		<section className="loading_page">
			<div>
				<div class="golden_header">
					<div>
						<img className="poly_logo" src={cci_logo} width="15%" alt="CCI_logo"/>
						{/* <img className="satellite" src={satellite_logo} width="15%" alt="Satellite_logo"/> */}
						<img className="satellite" src={satellite_large} width="2.5%" alt="Satellite_logo"/>
					</div>
				</div>

				{/* <br />
				<br /> */}
				
				
					<Box >
						<img className="space_logo" src={logo} alt="Logo" />
					</Box>
				

				<h2 className="load_info">
					Loading {!scene_name.includes("Apartment") && "the"} <span className="location_highlight">{scene_name}</span>
					<span className="load-info"> ...</span> 
				</h2>
				<br />
				<div className="row large-2">
					<ProgressBar  percent={percent} />
					<p className="txt-extra">{parseInt(percent * 100)}%</p>
				</div>

			</div>
		</section>
	)
}

export default Unity_Loading
