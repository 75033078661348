import './css/App.css';
import Unity_Embed from './unity/components/Unity_Embed'

function App(props) {
  var SCENE_NAME = 'Luna Practice';
  var AWS_NAME = 'lunaPractice';

  // var SCENE_NAME = "Control Center";
  // var AWS_NAME = "controlCenter";

  return (
      // eslint-disable-next-line react/jsx-pascal-case
      <Unity_Embed
        AWS_NAME={AWS_NAME}
        scene_name={SCENE_NAME}
        public={false}
        loaderUrl={`unity builds/${AWS_NAME}/web/${AWS_NAME}.loader.js`}
        dataUrl={`unity builds/${AWS_NAME}/web/${AWS_NAME}.data`}
        frameworkUrl={`unity builds/${AWS_NAME}/web/${AWS_NAME}.framework.js`}
        codeUrl={`unity builds/${AWS_NAME}/web/${AWS_NAME}.wasm`}
        sceneCompletionCode={''}
      />
  );
}

export default App;
